<!-- 停驶绿码 -->
<template>
  <div>
    <div class="fer_huwef">
      <img src="../../../image/tingshizhuanlvma.png" alt="" />
    </div>
    <div class="fw_hygf">
      <div class="erg_ggywef">
        <div class="erg_gftwef">
          <img src="../../../image/tingshiguizhe.png" alt="" />
        </div>
        <div class="erg_huwf">
          <p class="erg_huud">
            1、“车世际”会员车辆停止行驶期间可申请平台专用停驶绿码，
            绿码在车世际可换取续保保费补贴；
          </p>
          <p class="erg_huud">
            2、申请绿码须拍摄保险期内保单照片或上传电子版保单与车辆行驶证照片；
          </p>
          <p class="erg_huud">
            3、申请绿码须在停驶时拍摄车辆仪表公里数照片，之后每日在平台签到，停驶满24小时获取绿码一枚，停驶结束时上传车辆公里数照片；
          </p>
          <p class="erg_huud">
            4、绿码在本平台续保保费补贴计算方式为：续保保费/365*绿码个数=保费补贴，保费补贴获取流程：会员支付续保保费给平台指定保险公司——会员上传续保电子保单——30个工作日内平台支付续保补贴到会员平台账户——会员提现或用于平台其它项目消费；
          </p>
          <p class="erg_huud">
            5、平台会员首年获取绿码不超过90天，首年在平台续保会员次年获取绿码不超过180天，未在平台续保会员，次年获取绿码不超过90天；
          </p>
        </div>
      </div>
      <div>
        <div class="fwf_hyuwf">
          <div class="erg_gygwef">
            <img src="../../../image/tingchequanyi.png" alt="" />
          </div>
          <div class="erg_uhhhv">
            <p class="erg_hygtft">1、停驶1天（大于24小时）获取绿码一枚；</p>
            <p class="erg_hygtft">2、绿码可用于抵扣续保保费</p>
            <p class="erg_hygtft">3、绿码可领取90天/180天</p>
            <p class="erg_hygtft">4、金币可在平台提现或用于平台其它产品消费</p>
            <p class="erg_hygtft">
              5、续保补贴在平台提现或用于平台其它产品消费
            </p>
          </div>
        </div>
         <div class="fwf_hyuwf">
          <div class="erg_gygwef">
            <img src="../../../image/tingchequanyi.png" alt="" />
          </div>
          <div class="huhu_huherg">
              <img src="../../../image/quan_image.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {};
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.fer_huwef {
  width: 100%;
  padding-top: 91px;
  padding-bottom: 97px;
  display: flex;
  justify-content: center;
}
.fw_hygf {
  width: 100%;
  height: 872px;
  background: linear-gradient(0deg, #09b3fe 0%, #00d3ff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.erg_ggywef {
  width: 700px;
  margin-right: 40px;
}
.erg_gftwef {
  width: 700px;
  height: 85px;
  background: #09b4fe;
  opacity: 1;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.erg_huwf {
  width: 700px;
  height: 547px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 0px 24px 24px;
  padding: 0 30px;
}
.erg_huud {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
  padding-top: 40px;
}
.fwf_hyuwf {
  width: 700px;
  padding-bottom: 40px;
}
.fwf_hyuwf:last-child{
    padding-bottom: 0;
}
.erg_gygwef {
  width: 700px;
  height: 85px;
  background: #09b4fe;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.erg_hygtft {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 28px;
  color: #333333;
}
.erg_uhhhv {
  width: 700px;
  height: 211px;
  background: #ffffff;
  opacity: 1;
  border-radius: 0px 0px 24px 24px;
  padding: 40px 30px 0 30px;
}
.huhu_huherg{
    width: 700px;
height: 211px;
background: #FFFFFF;
opacity: 1;
border-radius: 0px 0px 24px 24px;
display: flex;
justify-content: center;
align-items: center;
}
</style>